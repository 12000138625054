@tailwind base;
@tailwind components;
@tailwind utilities;

@import "swiper/swiper.scss";

//@layer utilities {
.h-18 {
  height: 76px;
}
.blog-content {
  table-layout: fixed;
  width: 100%;
  display: table;
  @apply p-2;
}
.game-full {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.blog-content {
  @apply text-sm;
  p code {
    @apply font-bold;
  }
  pre {
    @apply my-4;
  }
  ul {
    @apply list-disc pl-10;
  }
  a {
    @apply text-blog-title hover:underline font-medium;
  }
  p {
    @apply mt-2;
  }
  h2 {
    @apply font-bold text-xl my-2;
  }
  h3 {
    @apply font-bold text-lg my-2;
  }
  h4{
    @apply font-bold my-2;
  }
  figure.wp-block-image{
    display:inline-block;
    margin-top: 10px;
  }
  .img-caption-container, .wp-block-image {
    @apply p-2 bg-block-light my-2;
    br {
      @apply hidden;
    }
    span, .wp-element-caption {
      @apply text-sm italic text-white;
    }
  }
}
.flex-basis-0 {
  flex-basis: 0;
}
.lazy-60-60 {
  width: 60px;
  height: 60px;
}
.lazy-150-150 {
  width: 150px;
  height: 150px;
}
.from-dark {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}
.text-shadow {
  text-shadow: 1px 2px 3px black;
}
.news-badge {
  position: relative;
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 40px solid #424558;
    border-right: 20px solid transparent;
    position: absolute;
    top: 0;
    right: -20px;
  }
  &:after {
    content: "";
    height: 40px;
    width: 9999px;
    position: absolute;
    top: 0;
    right: 100%;
    background-color: #424558;
  }
}

.react-cookie-law-manage-btn {
  display: none!important;
}

#__next {
  @apply antialiased min-h-screen min-h-screen-ios flex flex-col py-0-safe bg-site text-white;
}

.responsive {
  @apply flex-grow flex w-full sm:max-w-tablet md:max-w-laptop lg:max-w-desktop mx-auto px-4 sm:px-0 overflow-hidden;
}

.section-title {
  @apply text-xl font-light uppercase border-b border-black py-2 mb-4 mt-4;
}

.link {
  @apply text-white hover:text-primary transition-colors;
}

.swiper-container {
  @apply w-full h-full;
}

.overflow-visible-r {
  overflow: visible !important;
}

.editors-note{
  color: white;
  padding: 10px 25px;
  background-color: rgba(66, 69, 88, var(--tw-bg-opacity));
  margin-bottom: 20px;
  strong{
    margin: 0 auto;
  }
  a{
    color: rgba(185, 213, 94, var(--tw-text-opacity));
  }
  p{
    padding: 0 auto;
    margin: 0 auto;
  }
  &:after{
    content: "editors note";
    font-style: italic;
    margin-top: 5px;
    display: inline-block;
    color: #666;
  }
}
